<template>
    <r-e-dialog title="换房" class="layer-contract-detail" :visible.sync="dialogVisible" :show-footer="type!=='chakan'" top="10vh"
                :footerBtnName="state=== 2 ? ['取消','确定'] : ['取消','新签']" width="670px"
                fullscreen @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <div style="height: 75vh;">
            <div style="height: 100%;width: 100%;">
                <el-steps :active="active" style="margin-bottom: 20px;" finish-status="success" process-status="finish">
                    <el-step title="申请-管家申请">
                        <div slot="description">
                            <div>申请人: {{submitter}}</div>
                            <div>申请时间: {{submitDate}}</div>
                        </div>
                    </el-step>
                    <el-step title="处理-租赁部确认">
                        <div slot="description">
                            <div>确认人: {{confirmUser}}</div>
                            <div v-if="active>=2">确认时间: {{confirmDate}}</div>
                        </div>
                    </el-step>
                    <el-step title="新签-管家操作">
                        <div slot="description" v-if="active>=2">
                            <div>处理人: {{handleUser}}</div>
                            <div v-if="active>=3">处理时间: {{handleDate}}</div>
                        </div>
                    </el-step>
                    <el-step title="查看"></el-step>
                </el-steps>

                <div style="height: 90%;display: flex;flex-flow: column wrap;">
                    <div class="boder-right width33b padding_0_30">
                        <div class="title">旧房地址</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">{{formPublish.oldApartmentName}}</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="title">租户信息</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">{{formPublish.leasorName}}</div>
                    </div>

                    <div class="boder-right width33b padding_0_30 flex">
                        <div style="background-color: #F6F6F6;border-radius: 5px;color:#333333;padding: 10px 20px;">
                            <span>换房时间 </span>
                            <span>{{ formPublish.changeDate }}</span>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="title">换房原因</div>
                        <el-button :type="formPublish.reasonCode===2?'primary':''" style="font-size: 14px;padding: 8px 15px;"
                                   @click="setReason(2,'客户')">客户原因</el-button>
                        <el-button :type="formPublish.reasonCode===1?'primary':''" style="font-size: 14px;padding: 8px 15px;"
                                   @click="setReason(1,'房间')">房间原因</el-button>
                    </div>

                    <div class="boder-right width33b fw600 padding_10_30">旧房退费</div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('房租退费',formPublish.quitRentalPayBill,true)">房租</el-link>
                            </div>
                            <div class="text-right width100">{{ numberFormat(formPublish.quitRentalAmountTotal) }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('服务费退费',formPublish.quitServicePayBill,true)">服务费</el-link>
                            </div>
                            <div class="text-right width100">{{ numberFormat(formPublish.quitServiceAmountTotal) }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b fw600 padding_10_30">旧房扣费</div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">费用类型</div>
                            <div class="text-right width100">合计</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('房租扣费',formPublish.payOldRentalPayBill)">房租</el-link>
                            </div>
                            <div class="text-right width100">{{ numberFormat(formPublish.payOldRentalAmountTotal) }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('服务费扣费',formPublish.payOldServicePayBill)">服务费</el-link>
                            </div>
                            <div class="text-right width100">{{ numberFormat(formPublish.payOldServiceAmountTotal) }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('水费扣费',formPublish.payWaterPayBill)">水费</el-link>
                            </div>
                            <div class="text-right width100">{{ numberFormat(formPublish.oldWtaterAmount)}}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('电费扣费',formPublish.payElectPayBill)">电费</el-link>
                            </div>
                            <div class="text-right width100">{{ numberFormat(formPublish.oldElectAmount) }}</div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('清洁费扣费',formPublish.payCleanPayBill)">清洁费</el-link>
                            </div>
                            <div class="text-right width100">{{numberFormat(formPublish.payCleanAmount)}}</div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('家具损坏费扣费',formPublish.payMaintianPayBill)">家具损坏费</el-link>
                            </div>
                            <div class="text-right width100">{{numberFormat(formPublish.payMaintianAmount)}}</div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('其他费用扣费',formPublish.payOtherPayBill)">其他费用</el-link>
                            </div>
                            <div class="text-right width100">{{numberFormat(formPublish.payOtherAmount)}}</div>
                        </div>
                    </div>
                    <div v-if="formPublish.reasonCode===2" class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-link type="primary" @click="goBill('换房手续费扣费',formPublish.payChangePayBill)">换房手续费</el-link>
                            </div>
                            <div class="text-right width100">{{numberFormat(formPublish.payChangeAmount)}}</div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>旧房金额（元）
                                <span class="fw600" style="margin-left: 20px">{{numberFormat(oldFangAmountTotal)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>旧房扣费总额 = 房租 + 服务费 + 杂费</div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="title">新房地址</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">{{formPublish.newApartmentName}}</div>
                    </div>
                    <div class="boder-right width33b padding_10_30 fw600">新房费用</div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">费用类型</div>
                            <div class="text-right width100">合计</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">押金</div>
                            <div class="text-right width100">{{ numberFormat(formPublish.payDepoAmount) }}</div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">房租</div>
                            <div class="text-right width100">{{ numberFormat(formPublish.payRentalAmount) }}</div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">服务费</div>
                            <div class="text-right width100">{{ numberFormat(formPublish.payServiceAmount) }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>新房金额（元）
                                <span class="fw600" style="margin-left: 20px">{{numberFormat(newFangAmountTotal)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>新房费用总额 = 房租 + 服务费 + 押金</div>
                        </div>
                    </div>
                    <div class="flex align-baseline width33b padding_10_30 fw600">
                        <div style="width: 50px;">总计:</div>
                        <div>
                            <div style="font-size: 24px;">{{ numberFormat(formPublish.totalAmount) }}</div>
                            <div v-if="formPublish.totalAmount<0" style="font-size: 12px;color:#FF6740;">退还给租客{{numberFormat(-formPublish.totalAmount)}}</div>
                            <div v-else style="font-size: 12px;color: #FF6740;">向租客收取{{numberFormat(formPublish.totalAmount)}}</div>
                        </div>
                    </div>
                    <div class="flex align-baseline width33b padding_10_30 fw600">
                        <div>总计 = 房租退款({{numberFormat(formPublish.rentalDrawback)}}) + 杂费退款({{numberFormat(formPublish.expensesDrawback)}}) + 房租支付({{numberFormat(formPublish.rentalPay)}}) + 杂费付款({{numberFormat(formPublish.expensesPay)}}) + 押金付款({{numberFormat(formPublish.depoPay)}})</div>
                    </div>
                    <div style="width: 25%;padding: 10px 30px">
                        <div style="font-weight: 600;margin-bottom: 10px">上传图片</div>
                        <div style="padding-left: 20px;">
                            <upload-picture-card :uuidList="imgList" :limit="24" @on-success="handleSuccess"
                                                 @on-remove="handleRemove"/>
                        </div>
                    </div>

                    <div style="width: 25%;padding: 10px 30px;">
                        <div style="font-weight: 600;padding-bottom: 10px">备注</div>
                        <el-input type="textarea" v-model="formPublish.comment" :rows="3" style="width: 100%;"
                                  placeholder="请输入内容"/>
                    </div>
                </div>
            </div>
        </div>
        <r-e-dialog :title="billTitle" :visible.sync="billSwitch" show-footer top="5vh" width="1000px" :showFooter="false"
                    @click-cancel="billListClose" v-dialog-drag>
            <r-e-table ref="tableRef" :data="billList" :height="500" :showPagination="false"
                       show-summary :summary-method="getSummaries">
                <el-table-column label="账单时间">
                    <template slot-scope="{ row }">{{timeFormat(row.startDate)+"-"+timeFormat(row.endDate)}}</template>
                </el-table-column>
                <el-table-column label="账单金额" prop="amount">
                    <template slot-scope="{ row }">{{numberFormat(row.amount/100)}}</template>
                </el-table-column>
                <el-table-column v-if="billType" label="退费金额" prop="checkOutAmount">
                    <template slot-scope="{ row }">{{numberFormat(row.checkOutAmount/100)}}</template>
                </el-table-column>
            </r-e-table>
        </r-e-dialog>
    </r-e-dialog>
</template>

<script>
    import {
        getRenewalRoomChangeInfo,
        renewalRoomChangeIsCanAudit,
        renewalRoomChangeLeasingAudit,
        renewalRoomChangeNewContractSign
    } from "@/api/work-order-management";
    import { MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    import {timeFormat, ParseIntFormat, ParseFloatFormat, numberFormat} from "@custom";
    export default {
        name: "dialog-huanfang",
        data(){
            return {
                dialogVisible: false,
                formPublish: {
                    changeDate: null,
                    checkOutRent: null,
                    checkOutRentUuid: null,
                    comment: null,
                    disposeDate: null,
                    disposeUserName: null,
                    expenseTotalAmount: null,
                    leasorName: null,
                    leasorPhone: null,
                    leasorUuid: null,
                    newApartmentDepoAmount: null,
                    newApartmentName: null,
                    newApartmentRentalAmount: null,
                    newApartmentServiceAmount: null,
                    newApartmentUuid: null,
                    newContractUuid: null,
                    noPaidOldApartmentPayBill: null,
                    oldApartmentName: null,
                    oldApartmentUuid: null,
                    oldContractUuid: null,
                    oldElectAmount: null,
                    oldWtaterAmount: null,
                    payChangeAmount: null,
                    payChangePayBill: null,
                    payCleanAmount: null,
                    payCleanPayBill: null,
                    payDepoAmount: null,
                    payElectAmount: null,
                    payElectAmountSplit: null,
                    payElectPayBill: null,
                    payMaintianAmount: null,
                    payMaintianPayBill: null,
                    payOldRentalAmount: null,
                    payOldRentalAmountSplit: null,
                    payOldRentalAmountTotal: null,
                    payOldRentalPayBill: null,
                    payOldServiceAmount: null,
                    payOldServiceAmountSplit: null,
                    payOldServiceAmountTotal: null,
                    payOldServicePayBill: null,
                    payOtherAmount: null,
                    payOtherPayBill: null,
                    payRentalAmount: null,
                    payRentalPayBill: null,
                    payServiceAmount: null,
                    payServicePayBill: null,
                    payWaterAmount: null,
                    payWaterAmountSplit: null,
                    payWaterPayBill: null,
                    quitRentalAmount: null,
                    quitRentalAmountSplit: null,
                    quitRentalAmountTotal: null,
                    quitRentalPayBill: null,
                    quitServiceAmounSplit: null,
                    quitServiceAmount: null,
                    quitServiceAmountTotal: null,
                    quitServicePayBill: null,
                    reantalTotalAmount: null,
                    reasonCode: null,
                    reasonName: null,
                    remittanceDate: null,
                    remittanceUserName: null,
                    totalAmount: null,
                    uuid: null,
                    workPictures: null,
                    rentalDrawback: null, //房租退款
                    expensesDrawback: null, //杂费退款
                    rentalPay: null, //房租支付
                    expensesPay: null,//杂费付款
                    depoPay: null,//押金付款
                },
                imgList: [],
                uuid: null,
                state: null,
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                billTitle: null,
                billSwitch: false,
                billType: false,
                billList: [],
                active: 3,
                submitter: null,
                submitDate: null,
                confirmUser: null,
                confirmDate: null,
                handleUser: null,
                handleDate: null,
                oldFangAmountTotal: null,
                newFangAmountTotal: null,
                type: null,
            };
        },
        components: {
            uploadPictureCard: ()=>import("@/components/Upload/upload-picture-card"),
        },
        methods:{
            async openDialog(data, type) {
                let that = this;
                that.type = type;
                let oldFormPublish = that.formPublish;
                const {uuid, state, submitter, createDate} = data;
                that.uuid = uuid;
                that.state = state;
                if (state === 2) that.active = 1;
                if (state === 3) that.active = 2;
                if (state === 6) that.active = 3;

                const active = that.active;
                that.submitter = submitter;
                that.submitDate = timeFormat(new Date(createDate), "yyyy-MM-dd HH:mm:ss");
                //工单 uuid
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                let {info} = await getRenewalRoomChangeInfo({uuid}).catch(() => loading.close());
                loading.close();
                let {
                    changeDate, checkOutRent, checkOutRentUuid, comment, disposeDate, disposeUserName, expenseTotalAmount,
                    leasorName, leasorPhone, leasorUuid, newApartmentDepoAmount, newApartmentName, newApartmentRentalAmount,
                    newApartmentServiceAmount, newApartmentUuid, newContractUuid, noPaidOldApartmentPayBill, oldApartmentName,
                    oldApartmentUuid, oldContractUuid, oldElectAmount, oldWtaterAmount, payChangeAmount, payChangePayBill,
                    payCleanAmount, payCleanPayBill, payDepoAmount, payElectAmount, payElectAmountSplit, payElectPayBill,
                    payMaintianAmount, payMaintianPayBill, payOldRentalAmount, payOldRentalAmountSplit, payOldRentalAmountTotal,
                    payOldRentalPayBill, payOldServiceAmount, payOldServiceAmountSplit, payOldServiceAmountTotal, payOldServicePayBill,
                    payOtherAmount, payOtherPayBill, payRentalAmount, payRentalPayBill, payServiceAmount, payServicePayBill,
                    payWaterAmount, payWaterAmountSplit, payWaterPayBill, quitRentalAmount, quitRentalAmountSplit,
                    quitRentalAmountTotal, quitRentalPayBill, quitServiceAmounSplit, quitServiceAmount, quitServiceAmountTotal,
                    quitServicePayBill, reantalTotalAmount, reasonCode, reasonName, remittanceDate, remittanceUserName,
                    totalAmount, workPictures, rentalDrawback, expensesDrawback, rentalPay, expensesPay, depoPay
                } = info;
                changeDate = timeFormat(new Date(changeDate));
                expenseTotalAmount = ParseFloatFormat(expenseTotalAmount);
                newApartmentDepoAmount = ParseFloatFormat(newApartmentDepoAmount);
                newApartmentRentalAmount = ParseFloatFormat(newApartmentRentalAmount);
                newApartmentServiceAmount = ParseFloatFormat(newApartmentServiceAmount);
                oldElectAmount = ParseFloatFormat(oldElectAmount);
                oldWtaterAmount = ParseFloatFormat(oldWtaterAmount);
                payChangeAmount = ParseFloatFormat(payChangeAmount);
                payCleanAmount = ParseFloatFormat(payCleanAmount);
                payDepoAmount = ParseFloatFormat(payDepoAmount);
                payMaintianAmount = ParseFloatFormat(payMaintianAmount);
                payOldRentalAmountTotal = ParseFloatFormat(payOldRentalAmountTotal);
                payOldServiceAmountTotal = ParseFloatFormat(payOldServiceAmountTotal);
                payOtherAmount = ParseFloatFormat(payOtherAmount);
                payRentalAmount = ParseFloatFormat(payRentalAmount);
                payServiceAmount = ParseFloatFormat(payServiceAmount);
                quitRentalAmountTotal = ParseFloatFormat(quitRentalAmountTotal);
                quitServiceAmountTotal = ParseFloatFormat(quitServiceAmountTotal);
                reantalTotalAmount = ParseFloatFormat(reantalTotalAmount);
                totalAmount = ParseFloatFormat(totalAmount);
                rentalDrawback = ParseFloatFormat(rentalDrawback);
                expensesDrawback = ParseFloatFormat(expensesDrawback);
                rentalPay = ParseFloatFormat(rentalPay);
                expensesPay = ParseFloatFormat(expensesPay);
                depoPay  = ParseFloatFormat(depoPay);
                this.confirmUser = disposeUserName ? disposeUserName : active === 1 ? localStorage.getItem("userName") : '';
                this.confirmDate = disposeDate ? timeFormat(new Date(disposeDate), "yyyy-MM-dd HH:mm:ss") : '';
                this.handleUser = remittanceUserName ? remittanceUserName : active === 2 ? localStorage.getItem("userName") : '';
                this.handleDate = remittanceDate ? timeFormat(new Date(remittanceDate), "yyyy-MM-dd HH:mm:ss") : '';
                that.imgList = workPictures ? workPictures.split(";").filter(item => item !== "") : [];

                that.formPublish = {
                    ...oldFormPublish, changeDate, checkOutRent, checkOutRentUuid, comment, disposeDate, disposeUserName,
                    expenseTotalAmount, leasorName, leasorPhone, leasorUuid, newApartmentDepoAmount, newApartmentName,
                    newApartmentRentalAmount, newApartmentServiceAmount, newApartmentUuid, newContractUuid,
                    noPaidOldApartmentPayBill, oldApartmentName, oldApartmentUuid, oldContractUuid, oldElectAmount,
                    oldWtaterAmount, payChangeAmount, payChangePayBill, payCleanAmount, payCleanPayBill, payDepoAmount,
                    payElectAmount, payElectAmountSplit, payElectPayBill, payMaintianAmount, payMaintianPayBill,
                    payOldRentalAmount, payOldRentalAmountSplit, payOldRentalAmountTotal, payOldRentalPayBill,
                    payOldServiceAmount, payOldServiceAmountSplit, payOldServiceAmountTotal, payOldServicePayBill,
                    payOtherAmount, payOtherPayBill, payRentalAmount, payRentalPayBill, payServiceAmount, payServicePayBill,
                    payWaterAmount, payWaterAmountSplit, payWaterPayBill, quitRentalAmount, quitRentalAmountSplit,
                    quitRentalAmountTotal, quitRentalPayBill, quitServiceAmounSplit, quitServiceAmount, quitServiceAmountTotal,
                    quitServicePayBill, reantalTotalAmount, reasonCode, reasonName, remittanceDate, remittanceUserName,
                    totalAmount, workPictures, rentalDrawback, expensesDrawback, rentalPay, expensesPay, depoPay
                };
                that.setTotal(that.formPublish);
                that.dialogVisible = true;
            },
            setReason(code, name) {
                this.formPublish.reasonCode = code;
                this.formPublish.reasonName = name;
            },
            clickSubmit() {
                let that = this;
                const state = that.state;
                const uuid = that.uuid;
                const h = this.$createElement;
                if (state === 2) {
                    that.$confirm(h('div', {style: {color: 'red'}}, '您是否确认完成'), '注意', {type: 'warning'})
                        .then(() => {
                            const loadingOptions = that.loadingOptions;
                            const loading = that.$loading({...loadingOptions});
                            renewalRoomChangeIsCanAudit({uuid}).then(res => {
                                renewalRoomChangeLeasingAudit({uuid}).then(res => {
                                    loading.close();
                                    MessageSuccess('租赁部确认完成');
                                    that.clickCancel();
                                }).catch(() => loading.close());
                            }).catch(() => loading.close());
                        }).catch(() => {});
                }
                if(state === 3) {
                    that.$confirm(h('div', {style: {color: 'red'}}, '您是否确认完成并新签合同'), '注意', {type: 'warning'})
                        .then(() => {
                            const loadingOptions = that.loadingOptions;
                            const loading = that.$loading({...loadingOptions});
                            renewalRoomChangeNewContractSign({uuid}).then(res => {
                                loading.close();
                                MessageSuccess('换房完成');
                                let {info: {data}} = res;
                                if (data) {
                                    let {url} = data;
                                    let tempwindow = window.open("", '_blank');
                                    tempwindow.location = url;
                                }
                                that.clickCancel();
                            }).catch(() => loading.close());
                        }).catch(() => {});
                }
            },
            clickCancel() {
                this.$emit('handleSearch');
                this.dialogVisible = false;
                this.formPublish = {};
            },

            setTotal(data){
                let oldHuanFangData = this.huanFangData;
                let {
                    expenseTotalAmount, oldElectAmount, oldWtaterAmount, payChangeAmount, payCleanAmount, payDepoAmount,
                    payMaintianAmount, payOldRentalAmountTotal, payOldServiceAmountTotal, payOtherAmount, payRentalAmount,
                    payServiceAmount, quitRentalAmountTotal, quitServiceAmountTotal, reantalTotalAmount, totalAmount
                } = data;
                expenseTotalAmount = parseFloat(expenseTotalAmount);
                oldElectAmount = parseFloat(oldElectAmount);
                oldWtaterAmount = parseFloat(oldWtaterAmount);
                payChangeAmount = parseFloat(payChangeAmount);
                payCleanAmount = parseFloat(payCleanAmount);
                payDepoAmount = parseFloat(payDepoAmount);
                payMaintianAmount = parseFloat(payMaintianAmount);
                payOldRentalAmountTotal = parseFloat(payOldRentalAmountTotal);
                payOldServiceAmountTotal = parseFloat(payOldServiceAmountTotal);
                payOtherAmount = parseFloat(payOtherAmount);
                payRentalAmount = parseFloat(payRentalAmount);
                payServiceAmount = parseFloat(payServiceAmount);
                quitRentalAmountTotal = parseFloat(quitRentalAmountTotal);
                quitServiceAmountTotal = parseFloat(quitServiceAmountTotal);
                reantalTotalAmount = parseFloat(reantalTotalAmount);
                totalAmount = parseFloat((totalAmount).toFixed(2));
                const oldFangAmountTotal = payOldRentalAmountTotal + payOldServiceAmountTotal + oldWtaterAmount + oldElectAmount + payCleanAmount + payMaintianAmount + payOtherAmount + payChangeAmount;
                const newFangAmountTotal = payDepoAmount + payRentalAmount + payServiceAmount;
                reantalTotalAmount = (payOldRentalAmountTotal + payRentalAmount + payDepoAmount) - quitRentalAmountTotal;
                expenseTotalAmount = (payOldServiceAmountTotal + oldWtaterAmount + oldElectAmount + payCleanAmount + payMaintianAmount + payOtherAmount + payChangeAmount + payServiceAmount) - quitServiceAmountTotal;
                totalAmount = reantalTotalAmount + expenseTotalAmount;
                this.huanFangData = {
                    ...oldHuanFangData, expenseTotalAmount, oldElectAmount, oldWtaterAmount, payChangeAmount, payCleanAmount,
                    payDepoAmount, payMaintianAmount, payOldRentalAmountTotal, payOldServiceAmountTotal, payOtherAmount,
                    payRentalAmount, payServiceAmount, quitRentalAmountTotal, quitServiceAmountTotal, reantalTotalAmount,
                    totalAmount,
                };
                this.newFangAmountTotal = newFangAmountTotal;
                this.oldFangAmountTotal = oldFangAmountTotal;
            },

            numberFormat(number = 0){
                number = parseFloat(number);
                return numberFormat(number);
            },

            timeFormat(timedate, format = "yyyy-MM-dd") {
                if(timedate) return timeFormat(new Date(timedate), format);
                else return "";
            },

            handleSuccess({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else return name;
                });
            },

            handleRemove({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else return name;
                });
            },

            goBill(title, data, type = false) {
                if (data.length>0) {
                    let that = this;
                    that.billTitle = title;
                    that.billType = type;
                    that.billList = data;
                    that.billSwitch = true;
                } else MessageInfo('无' + title + '账单');
            },

            billListClose() {
                this.billTitle = null;
                this.billList = null;
                this.billSwitch = false;
            },

            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0);
                        sums[index] = numberFormat(sums[index]/100);
                    } else sums[index] = '';

                });
                return sums;
            }
        }
    }
</script>

<style scoped>
    .row {
        margin-bottom: 10px;
        padding: 10px 20px;
        border: 1px solid #D7D7D7;
    }

    .width300 {
        width: 300px;
    }

    .width100 {
        width: 100px;
    }

    .boder-right {
        border-right: 1px solid #D7D7D7;
    }

    .title {
        border-left: 5px #F5A623 solid;
        padding-left: 5px;
        margin: 15px 0;
        font-weight: 600;
    }

    .bg-E6E6E6 {
        background-color: #E6E6E6;
    }

    .bg-E2F5FF {
        background-color: #E2F5FF;
    }

    .bg-F8F8F8 {
        background-color: #F8F8F8;
    }

    .width33b {
        width: 25%;
    }

    .padding_0_30 {
        padding: 0 30px
    }

    .padding_10_30 {
        padding: 10px 30px;
    }

    .fw600 {
        font-weight: 600;
    }
</style>